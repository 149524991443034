<template>
  <div>
    <b-sidebar id="sidebar-settings-table" sidebar-class="sidebar-lg" :visible="importLeadsSidebar.visible"
      bg-variant="white" shadow backdrop no-header right @hidden="clear"
      @change="(val) => mutateImportLeadsSidebar({ visible: val })">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Importar Leads</h4>
          <div>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <b-form class="p-2" @submit.prevent="onSubmit" @reset.prevent="resetForm">
          <h5>Importe seus leads do Projeto Meu Network!</h5>
          <small>É possível importar até 500 leads com nome e telefone obrigatórios</small>          
          <div class="demo-inline-spacing">

            <file-chooser
              id="file"
              label="Meu Network"
              v-model="importFile"
              :disabled="saving"
              :thereIsError="v$.importFile.$error && v$.importFile.$invalid"
              @clear="onSubmit"
              only-documents
            />

          </div>


          <!-- </b-row> -->
          <div class="d-flex mt-2">
            <b-button :disabled="saving" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
              type="submit">
              {{ saving ? 'Salvando as informações...' : 'Salvar' }}
            </b-button>
          </div>
        </b-form>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BButton, BFormInput, BFormTextarea, BCol
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'
import BCardCode from '@core/components/b-card-code'
import FileChooser from '@/modules/shared/components/FileChooser'
import { required, requiredIf } from "@vuelidate/validators";
import { getVueSelectErrorClass } from "@/helpers/validators";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BCol,
    BCardCode,
    FileChooser
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      importFile: undefined,
      saving: false
    }
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      importFile: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      importLeadsSidebar: types.IMPORT_LEADS_SIDEBAR
    })
  },
  methods: {
    ...mapMutations({
      mutateImportLeadsSidebar: types.MUTATE_IMPORT_LEADS_SIDEBAR,
    }),
    ...mapActions({
      saveImportLeadsSidebar: types.SAVE_IMPORT_LEADS_SIDEBAR,
    }),
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect)
        return;

      let formData = new FormData();
      formData.append('file', file.files[0]);
      this.saving = true
      this.saveImportLeadsSidebar(formData)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Leads importados com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateImportLeadsSidebar({ visible: false })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao importar os leads. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false
        });
    },
    clear() {
      this.importFile = undefined;
      this.v$.$reset();
    }
  }
}
</script>
